// export const base_url = "http://192.168.1.49:5000";
 export const base_url = "https://jagatjit.mindspacetech.com/V1";
// export const base_url = "http://dietcheck.mindspacetech.com:5093";


// create order product api
export const get_product_url =base_url + "/api/product/getProductList";
export const save_order =base_url + "/api/order/saveOrder";
export const get_branch =base_url + "/api/company/getBranch";
export const get_product_variant =base_url + "/api/product/getProductVariant";
export const get_order =base_url + "/api/order/getOrder";
