import './App.css';
import Header from "./Layout/Header";

import { Route, Routes, Redirect } from 'react-router-dom';

import CreateOrder from './Pages/CreateOrder/createorder';
import Dashboard from './Pages/Dashboard/dashboard';
import LoginPage from './Pages/Auth/login';
import OrderView from './Pages/CreateOrder/orderview';
function App() {
  return (
    <>
      {/* <Header/> */}
      <Routes>
        <Route path="/" element={<LoginPage />} />
       
       
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Createorder" element={<CreateOrder />} />
          <Route path="/Orderview" element={<OrderView/>} />
         
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </>
  );
}

export default App;
