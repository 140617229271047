import React from 'react';
import Hotel from '@mui/icons-material/Hotel';
import NightShelter from '@mui/icons-material/NightShelter';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { CChart } from '@coreui/react-chartjs';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';

function Dashcontent() {
    return (
        <div className='dashboard-div py-4'>
            <Container className='main-container'>
                <Card style={{ padding: '0', borderRadius: '8px', backgroundColor: '#fff' }}>
                    <Card.Body>
                        <Card.Title style={{ fontWeight: '450', color: '#000' }}>Dashboard</Card.Title>
                        <div className='row  mt-3'>
                            <div className='col-lg-3 mb-4'>
                                <div className='card p-2 card-1'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ color: '#154d03' }}>
                                        <div>
                                            <p>Total Orders</p>
                                            <h3>100</h3>
                                        </div>
                                        <div className='icon-dash'>

                                            <BorderColorOutlinedIcon fontSize='large' style={{ color: '#154d03' }} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 mb-4'>
                                <div className='card p-2 card-2'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ color: '#87044dcf' }}>
                                        <div>
                                            <p>Order per Month </p>
                                            <h3>50</h3>
                                        </div>
                                        <div className='icon-dash1'>
                                            <div>
                                                <CalendarMonthIcon fontSize='large' style={{ color: '#87044dcf' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 mb-4'>
                                <div className='card p-2 card-3'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ color: '#ce9714' }}>
                                        <div>
                                            <p>Average Contract </p>
                                            <h3>30</h3>
                                        </div>
                                        <div className='icon-dash2'>
                                            <div>
                                                <AddTaskIcon fontSize='large' style={{ color: '#ce9714' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 mb-4'>
                                <div className='card p-2 card-4'>
                                    <div className='d-flex justify-content-between align-items-center' style={{ color: '#601eb8' }}>
                                        <div>
                                            <p>Growth Rate</p>
                                            <h3>20</h3>
                                        </div>
                                        <div className='icon-dash3'>
                                            <div>
                                                <PersonAddAltIcon fontSize='large' style={{ color: '#601eb8' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-lg-5 mb-4'>
                                <div className='card chart-card'>
                                    <h5 style={{ padding: '10px' }}>Sales Growth for Market Segment</h5>
                                    <div className='p-2' style={{ width: '100%', height: 'auto' }}>
                                        <CChart
                                            type="doughnut"
                                            data={{
                                                labels: ['Super Seeder', 'Rotary Tiller', 'DSR', 'Mobile Shredder', 'Reaper Binder'],
                                                datasets: [
                                                    {
                                                        backgroundColor: ['#264C12', '#36721C', '#67A34D', '#8EBE79', '#8EBE79'],
                                                        data: [10, 20, 30, 40, 50],
                                                    },
                                                ],
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        labels: {
                                                            color: "#000",
                                                        }
                                                    }
                                                },
                                                responsive: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7 mb-4'>
                                <div className='card chart-card'>
                                    <h5 style={{ padding: '10px' }}>Sales per Rep</h5>
                                    <div className='p-2' style={{ width: '100%', height: 'auto' }}>
                                        <CChart
                                            type="bar"
                                            data={{
                                                labels: ['Category 1', 'Category 2', 'Category 3'],
                                                datasets: [
                                                    {
                                                        label: 'Product Suggested Category',
                                                        backgroundColor: '#202984',
                                                        data: [100, 200, 300],
                                                    },
                                                ],
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        labels: {
                                                            color: "#000",
                                                        }
                                                    }
                                                },
                                                scales: {
                                                    x: {
                                                        grid: {
                                                            color: "#000",
                                                        },
                                                        ticks: {
                                                            color: "#000",
                                                        },
                                                    },
                                                    y: {
                                                        grid: {
                                                            color: "#000",
                                                        },
                                                        ticks: {
                                                            color: "#000",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 footer py-3' style={{ textAlign: "right" }}>
                                <p>Designed and Developed by <a href='https://mindspacetech.com/' target='_blank'> MindSpace Software Technologies Pvt. Ltd.</a></p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default Dashcontent;
