import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Modal from '../Components/Modal';

const Header = ({ toggleSideColumn, toggleMainColumn }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const prompt = "Are you sure you want to logout";

  const userData=localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);

  const handleMenuButtonClick = () => {
    toggleSideColumn();
    toggleMainColumn(); // Toggle the main column
  };

  const onClose = () => {
    setShow(false);
  };

  const onLogoutHandler = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const onLogoutConfirm = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <header>
      <div className="container-fluid">
        <div className="row align-items-center border-bottom py-2">
          {/* Menu Button */}
          <div className="col-auto d-flex justify-content-start">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuButtonClick}
            >
              <MenuIcon />
            </IconButton>
          </div>

          {/* Logo Images */}
          <div className="col d-flex align-items-center">
            <img src='JJ_Logo.png' width="150" alt="Jagatjit Logo" className='logo ' />
            <img src='New_JJLogo.png' width="150" alt="Jagatjit Logo" className='newlogo mx-2' />
          </div>

          {/* User Info */}
          <div className="col-auto d-flex flex-column align-items-end">
            <div className="company-name">
              {parsedUserData.company_nm}
            </div>
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="d-flex align-items-center">
                <AccountCircleIcon className='usericon' />
                <div className="user-info">
                  <span className="user-name">{parsedUserData.dealer_nm}</span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-2">Change Password</Dropdown.Item>
                <Dropdown.Item onClick={(e) => onLogoutHandler(e)}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={onClose}
        onConfirm={onLogoutConfirm}
        title="Confirm Logout"
        message={prompt}
      />
    </header>
  );
};

export default Header;
