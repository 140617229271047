import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Modal from './Modal';

function Sidemenu() {
    const [show, setShow] = useState(false);
    const [isOrderManagementOpen, setIsOrderManagementOpen] = useState(false);
    const prompt = "Are you sure you want to logout";
    const location = useLocation();
    const navigate = useNavigate();

    const onClose = () => {
        setShow(false);
    }

    const onLogoutHandler = (e) => {
        e.preventDefault();
        setShow(true);
    }

    const onLogoutConfirm = () => {
        localStorage.clear();
        navigate("/");
    }

    const handleOrderManagementClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setIsOrderManagementOpen(prev => !prev); // Toggle the submenu
    }

    useEffect(() => {
        // Ensure the submenu is open if the current path is related to Order Management
        if (location.pathname.startsWith('/Createorder') || location.pathname.startsWith('/Orderview')) {
            setIsOrderManagementOpen(true);
        } else if (location.pathname.startsWith('/dashboard')) {
            setIsOrderManagementOpen(false);
        }
    }, [location.pathname]);

    const menuItems = [
        {
            name: "Dashboard",
            path: "/dashboard",
            icon: <DashboardIcon />
        },
        {
            name: "Order Management",
            path: "/order-management", // Added a path to the menu item for consistency
            icon: <PlaylistPlayIcon />,
            submenu: [
                {
                    name: "Create Order",
                    path: "/Createorder",
                    icon: <PlaylistAddIcon />
                },
                {
                    name: "View Orders",
                    path: "/Orderview",
                    icon: <PlaylistAddIcon />
                }
                // Add more submenu items as needed
            ]
        },
        // Add more hardcoded menu items as needed
    ];

    return (
        <div className='sidemenu-div'>
            <ul>
                {menuItems.map((menuItem, index) => (
                    <li key={index}>
                        {menuItem.submenu ? (
                            <>
                                <Link 
                                    className={location.pathname.startsWith(menuItem.path) ? 'active-menu' : 'menu'}
                                    to={menuItem.path}
                                    onClick={menuItem.name === "Order Management" ? handleOrderManagementClick : undefined}
                                >
                                    {menuItem.icon} {menuItem.name}
                                </Link>
                                {menuItem.name === "Order Management" && isOrderManagementOpen && (
                                    <ul className='submenu'>
                                        {menuItem.submenu.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <Link
                                                    className={location.pathname === subItem.path ? 'active-menu' : 'menu'}
                                                    to={subItem.path}
                                                >
                                                    {subItem.icon} {subItem.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </>
                        ) : (
                            <Link className={location.pathname === menuItem.path ? 'active-menu' : 'menu'} to={menuItem.path}>
                                {menuItem.icon} {menuItem.name}
                            </Link>
                        )}
                    </li>
                ))}
                <li>
                    <Link className='menu' onClick={(e) => onLogoutHandler(e)}>
                        <ExitToAppIcon /> Logout
                    </Link>
                </li>
            </ul>
            <Modal
                show={show}
                onHide={onClose}
                onConfirm={onLogoutConfirm}
                title="Confirm Logout"
                message={prompt}
            />
        </div>
    );
}

export default Sidemenu;
