import { CapacitorHttp } from "@capacitor/core";

// Improved axios_wrapper_general
const axios_wrapper_general = async (p_url, p_post_data, p_method) => {
    // Add any necessary authentication keys or tokens
    p_post_data.p_auth_key = "";
     p_post_data.p_usr_cd=1;

    // Prepare request options
    const postdata = {
        url: p_url,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data: p_post_data,
        method: p_method,
    };

    try {
        // Make HTTP request using CapacitorHttp
        const response = await CapacitorHttp.request(postdata);
        return response;
    } catch (error) {
        console.error('Error in axios_wrapper_general:', error);
        throw error; // Re-throw the error for handling in the calling code
    }
};

export default axios_wrapper_general;
