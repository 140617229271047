import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronDown } from 'react-icons/fa';
import Divider from '@mui/material/Divider';
import DataTable from 'react-data-table-component';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios_wrapper_general from "../../utils/axios_wrapper_general";
import { get_branch, get_product_url, get_product_variant, save_order } from "../../utils/endpoints";
import Modal from "../../Components/Modal";
import { showSuccess } from "../../utils/sweetalert";
import Swal from 'sweetalert2';

function Createorder() {
  // Initialize state with all form fields
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [formValues, setFormValues] = useState({
    p_sys_cd_product_variant: 0,
    p_sys_cd_product_implement: 0,
    p_sys_cd_branch: 0,
    p_order_date: getTodayDate(),
    p_expected_delivery_date: '',
    p_billing_address: getBillngAdd(),
    p_shipping_address: '',
    p_payment_status: '',
    p_remark: '',
    p_prize: 0,
    p_grant_price: 0,
    p_product: '',
    p_branch: '',
    p_variant: '',
    p_quantity: 1
  });

  const title = "Confirm Order"
  const message = "Are you sure you want to place this order?"

  // Initialize state for table data
  const [tableData, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [branchData, setBranchData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [show, setShow] = useState(false);
  const today = new Date().toISOString().split('T')[0];


  useEffect(() => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    // // Retrieve user data from localStorage
    // if (userData) {
    //   setFormValues(prevValues => ({
    //     ...prevValues,
    //     p_billing_address: userData.billing_to,
    //   }));
    // }
    getBranchList();
    getProductList();
  }, []);
  //handle modal
  const onClose = () => {
    setShow(false);
  }

  const onClickSubmitOrder = () => {
    setShow(true);
  }


  const getBranchList = () => {
    var postdata_1 = {
      p_sys_cd_company: 1
    };
    var method = "Post";

    axios_wrapper_general(get_branch, postdata_1, method).then((response) => {
      try {
        if (response.data.status == 200) {
          setBranchData(response?.data?.rows);
          console.log(response.data.rows);
        } else {
          console.log(response.data.msg);
        }
      } catch (error) {
        console.log("Error", error);
      }
    });
  }

  const getProductList = () => {
    var postdata_1 = {
    };
    var method = "Post";

    axios_wrapper_general(get_product_url, postdata_1, method).then((response) => {
      try {
        if (response.data.status == 200) {
          setProductList(response?.data?.rows);
          console.log("Product", response.data.rows);
        } else {
          console.log(response.data.msg);
        }
      } catch (error) {
        console.log("Error", error);
      }
    });
  }

  const getVariantList = (imp_cd) => {
    var postdata_1 = {
      p_sys_cd_product: imp_cd
    };
    var method = "Post";

    axios_wrapper_general(get_product_variant, postdata_1, method).then((response) => {
      try {
        if (response.data.status == 200) {
          setVariantList(response?.data?.rows);
          console.log("variant", response.data.rows);
        } else {
          console.log(response.data.msg);
        }
      } catch (error) {
        console.log("Error", error);
      }
    });
  }

  const saveOrderList = () => {
    var postdata_1 = {
      p_data: JSON.stringify(tableData)
    };
    var method = "Post";
    setShow(false);
    axios_wrapper_general(save_order, postdata_1, method).then((response) => {
      try {
        if (response.data.rows[0].status == 200) {
          setVariantList(response?.data?.rows);
          console.log("variant", response.data.rows);
          showSuccess(response.data.rows[0].msg);
          setTableData([]);
        } else {
          console.log(response.data.msg);
        }
      } catch (error) {
        console.log("Error", error);
      }
    });
  }

  // Function to get today's date formatted as dd/MM/yyyy
  function getTodayDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Ensure day is two digits
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
    const year = today.getFullYear();
    // return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;

  }

  function getTodayDateformat() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Ensure day is two digits
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;

  }

  function getBillngAdd() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    console.log("userData.billing_to", userData.billing_to);
    return userData.billing_to;
  }

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleProductChnage = (e) => {
    const selectedProductData = e.target.value;
    getVariantList(selectedProductData);
    const selectedProductObj = productList.find(product => product.m_sys_cd == selectedProductData);
    // console.log("selectedBranch",selectedBranchObj);
    setFormValues(prevValues => ({
      ...prevValues,
      p_sys_cd_product_implement: selectedProductObj.m_sys_cd,
      p_product: selectedProductObj.m_product_name,
      p_sys_cd_product_variant:0
    }));
  }

  const handleBranchChange = (e) => {
    const selectedBranchData = e.target.value;
    const selectedBranchObj = branchData.find(branch => branch.Branch_Code == selectedBranchData);
    console.log("selectedBranch", selectedBranchObj);
    setFormValues(prevValues => ({
      ...prevValues,
      p_sys_cd_branch: selectedBranchObj.Branch_Code,
      p_shipping_address: selectedBranchObj.Address,
      p_branch: selectedBranchObj.Branch_Name
    }));
  }

  const handleVariantChange = (e) => {
    const selectedVariantCode = e.target.value;
    const selectedVariantObj = variantList.find(variant => variant.m_sys_cd == selectedVariantCode);
    setFormValues(prevValues => ({
      ...prevValues,
      p_sys_cd_product_variant: selectedVariantObj.m_sys_cd,
      p_grant_price: formValues.p_quantity * selectedVariantObj.m_base_prize,
      p_prize: selectedVariantObj.m_base_prize,
      p_variant: selectedVariantObj.m_description
    }));
  }

  const handleQuantityChange = (e) => {
    const selectedQuantity = e.target.value;
    setFormValues(prevValues => ({
      ...prevValues,
      p_quantity: selectedQuantity,
      p_grant_price: selectedQuantity * formValues.p_prize,
    }));
  }

  // Function to handle date changes
  const handleDateChange = (date) => {
    setFormValues(prevValues => ({
      ...prevValues,
      p_expected_delivery_date: date,
    }));
  };

  const validateFields = (data) => {
    if (data.p_sys_cd_branch == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Branch must be selected.',
        confirmButtonText: 'OK'
      });
      return false;
    }

    if (data.p_shipping_address == '') {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Shipping Address cannot be empty.',
        confirmButtonText: 'OK'
      });
      return false;
    }

    if (data.p_expected_delivery_date == '') {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Expected Delivery Date cannot be empty.',
        confirmButtonText: 'OK'
      });
      return false;
    }


    if (data.p_sys_cd_product_implement == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Product Implement must be selected.',
        confirmButtonText: 'OK'
      });
      return false;
    }
    // Check if fields that should not be 0 are 0
    if (data.p_sys_cd_product_variant == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Product Variant must be selected.',
        confirmButtonText: 'OK'
      });
      return false;
    }

    if (data.p_quantity <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Product quantity should atleast be 1.',
        confirmButtonText: 'OK'
      });
      return false;
    }

    // if (data.p_remark === '') {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Validation Error',
    //     text: 'Remark cannot be empty.',
    //     confirmButtonText: 'OK'
    //   });
    //   isValid = false;
    // }
    return true;
  };

  // Function to handle form submission
  const handleAddOrder = () => {
    const isValid = validateFields(formValues);

    if (!isValid) {
      return;
    }

    console.log("formValues", formValues);
    if (editIndex !== null) {
      // Edit existing row
      const updatedTableData = tableData.map((item, index) =>
        index === editIndex
          ? { ...formValues }
          : item
      );
      setTableData(updatedTableData);
      setEditIndex(null);
    } else {
      // Add new row
      setTableData(prevData => [
        ...prevData,
        { ...formValues }
      ]);
    }

    // Clear form fields
    setFormValues({
      p_sys_cd_product_variant: 0,
      p_sys_cd_product_implement: 0,
      p_sys_cd_branch: 0,
      p_order_date: getTodayDate(),
      p_expected_delivery_date: '',
      p_billing_address: getBillngAdd(),
      p_shipping_address: '',
      p_payment_status: '',
      p_remark: '',
      p_prize: 0,
      p_grant_price: 0,
      p_product: '',
      p_quantity: 1,
      p_branch: '',
      p_variant: ''
    });
  };


  // Function to handle edit
  const handleEdit = (rowIndex) => {
    setEditIndex(rowIndex);
    setFormValues(tableData[rowIndex]);
  };

  // Function to handle delete
  const handleDelete = (rowIndex) => {
    setTableData(prevData => prevData.filter((_, index) => index !== rowIndex));
  };

  const columns = [
    { name: 'Order Date', selector: row => row.p_order_date },
    { name: 'Billing Address', selector: row => row.p_billing_address, wrap: true, width: "200px" },
    { name: 'Branch', selector: row => row.p_branch },
    { name: 'Shipping Address', selector: row => row.p_shipping_address, wrap: true, width: "200px" },
    { name: 'Expected Delivery Date', selector: row => row.p_expected_delivery_date, width: "200px" },
    { name: 'Product', selector: row => row.p_product, wrap: true, width: "120px" },
    { name: 'Product', selector: row => row.p_variant, wrap: true, width: "150px" },
    { name: 'Quantity', selector: row => row.p_quantity },
    { name: 'Grant Price(₹)', selector: row => row.p_grant_price,width: "120px" },
    { name: 'Remark', selector: row => row.p_remark, wrap: true, width: "250px" },
    {
      name: 'Edit',
      cell: (row, index) => (
        <Button
          onClick={() => handleEdit(index)}
          style={{
            backgroundColor: '#8fbd2d',
            color: 'white',
            padding: '2px 4px',
            minWidth: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <EditIcon style={{ color: 'white', fontSize: '15px' }} />
        </Button>
      )
    },
    {
      name: 'Remove',
      cell: (row, index) => (
        <Button
          onClick={() => handleDelete(index)}
          style={{
            backgroundColor: '#8fbd2d',
            color: 'white',
            padding: '2px 4px',
            minWidth: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <DeleteIcon style={{ color: 'white', fontSize: '15px' }} />
        </Button>
      )
    }
  ];

  const SubHeader = () => (
    <div style={{ padding: '10px', backgroundColor: '#f0f0f0' }}>
      <h5>Subheader: Detailed Overview of Orders</h5>
    </div>
  );

  return (
    <div className="dashboard-div py-4">
      <Container className='main-container'>
        <Card style={{ padding: '0', borderRadius: '8px', backgroundColor: '#fff' }}>
          <Card.Body>
            <Card.Title style={{ fontWeight: '450', color: '#000' }}>Create Order</Card.Title>
            <Form style={{ background: '#fff', borderRadius: '8px' }}>
              <Row className="align-items-start">
                {/* Left Column (4) */}
                <Col xs={12} md={4} className="mb-3">
                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Group controlId="formOrderDate">
                        <Form.Label className="formLabel">Order Date<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control form-control-sm"
                          value={getTodayDateformat()}
                          readOnly
                          style={{ backgroundColor: '#e9ecef', borderRadius: '4px' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Form.Group controlId="formBillingAddress">
                        <Form.Label className="formLabel">Billing Address<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          readOnly
                          name="p_billing_address"
                          className="form-control form-control-sm"
                          rows={3}
                          placeholder="Enter billing address"
                          value={formValues.p_billing_address}
                          onChange={handleChange}
                          style={{ borderRadius: '4px' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Form.Group controlId="formBranch">
                        <Form.Label className="formLabel">Branch<span style={{ color: 'red' }}>*</span></Form.Label>
                        <div className="custom-dropdown">
                          <Form.Control
                            as="select"
                            name="p_sys_cd_branch"
                            className="form-control form-control-sm"
                            value={formValues.p_sys_cd_branch}
                            onChange={(e) => handleBranchChange(e)}
                          >
                            <option disabled value={0}>Select branch</option>
                            {branchData.map((branch) => <option key={branch.Branch_Code} value={branch.Branch_Code}>{branch.Branch_Name}</option>)}

                          </Form.Control>
                          <FaChevronDown className="dropdown-icon" />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={1} className="mb-3">
                  <div className="vertical-line"></div>
                </Col>

                {/* Right Column (7) */}
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={6} className="mb-3">
                      <Form.Group controlId="formShippingAddress">
                        <Form.Label className="formLabel">Shipping Address<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          name="p_shipping_address"
                          className="form-control form-control-sm"
                          rows={2}
                          placeholder="Enter shipping address"
                          value={formValues.p_shipping_address}
                          onChange={handleChange}
                          style={{ borderRadius: '4px' }}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={6} className="mb-3">
                      <Form.Group controlId="formExpectedDeliveryDate">
                        <Form.Label className="formLabel">Expected Delivery Date<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          type="date"
                          name="p_expected_delivery_date"
                          value={formValues.p_expected_delivery_date}
                          onChange={(e) => handleDateChange(e.target.value)}
                          className="form-control form-control-sm"
                          style={{ borderRadius: '4px' }}
                          min={today}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group controlId="formImplement">
                        <Form.Label className="formLabel">Select Implement<span style={{ color: 'red' }}>*</span></Form.Label>
                        <div className="custom-dropdown">
                          <Form.Control as="select" className="form-control form-control-sm"
                            value={formValues.p_sys_cd_product_implement}
                            name="p_sys_cd_product_implement"
                            onChange={(e) => handleProductChnage(e)}>
                            <option disabled value={0}>Select product</option>
                            {productList.map((product) => <option key={product.m_sys_cd} value={product.m_sys_cd}>{product.m_product_name}</option>)}
                          </Form.Control>
                          <FaChevronDown className="dropdown-icon" />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group controlId="formProductVariant">
                        <Form.Label className="formLabel">Select Product Variant<span style={{ color: 'red' }}>*</span></Form.Label>
                        <div className="custom-dropdown">
                          <Form.Control
                            as="select"
                            name="p_sys_cd_product_variant"
                            className="form-control form-control-sm"
                            value={formValues.p_sys_cd_product_variant}
                            onChange={(e) => handleVariantChange(e)}
                          >
                            <option disabled value={0}>Select variant</option>
                            {variantList.map((variant) => <option key={variant.m_sys_cd} value={variant.m_sys_cd}>{variant.m_description}</option>)}
                          </Form.Control>
                          <FaChevronDown className="dropdown-icon" />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group controlId="formQuantity">
                        <Form.Label className="formLabel">Quantity<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          type="number"
                          min={1}
                          name="p_quantity"
                          placeholder="Quantity"
                          className="form-control form-control-sm"
                          value={formValues.p_quantity}
                          onChange={(e) => handleQuantityChange(e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group controlId="formPrize">
                        <Form.Label className="formLabel">Price(₹)<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="p_grant_price"
                          placeholder="Price"
                          className="form-control form-control-sm"
                          value={formValues.p_grant_price}
                          onChange={handleChange}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Form.Group controlId="formRemark">
                        <Form.Label className="formLabel">Remark</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="p_remark"
                          className="form-control form-control-sm"
                          rows={2}
                          placeholder="Enter any remarks here"
                          value={formValues.p_remark}
                          onChange={handleChange}
                          style={{ borderRadius: '4px' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div style={{ textAlign: 'right', marginTop: '16px' }}>
                <Button
                  style={{
                    borderRadius: '20px',
                    padding: '8px 16px',
                    backgroundColor: '#8fbd2d',
                    color: '#fff',
                  }}
                  variant="contained"
                  onClick={handleAddOrder}
                >
                  {editIndex !== null ? 'Update Order' : 'Add Order'}
                </Button>
              </div>
              <hr />
            </Form>
            <DataTable
              title="Order Summary List"
              columns={columns}
              data={tableData}
              noDataComponent="No orders to display"
              pagination
              highlightOnHover
              dense
              pointerOnHover
              subHeaderComponent={<SubHeader />}
            />
            {tableData.length > 0 ? <div style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button
                style={{
                  borderRadius: '20px',
                  padding: '8px 16px',
                  backgroundColor: '#8fbd2d',
                  color: '#fff',
                }}
                variant="contained"
                onClick={onClickSubmitOrder}
              >
                Place Order
              </Button>
            </div> : " "}
          </Card.Body>
        </Card>
      </Container>
      <Modal
        show={show}
        onHide={onClose}
        onConfirm={saveOrderList}
        title={title}
        message={message}
      />
    </div>
  );
}

export default Createorder;
