import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const userData={
    user_cd:1,
    company_nm:'Paras Engineering Pvt Ltd',
    dealer_nm:'Poonam Suryavanshi',
    billing_to:'Model Colony, 1087/B, Hara Krishna Mandir Rd., Modelcolony, Shivaji Nagar  Pune'
    };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === 'user@example.com' && password === 'password123') {
      navigate('/dashboard');
      localStorage.setItem("userData",JSON.stringify(userData));
    } else {
      setError('Invalid email or password');
    }
  };

  return (
    <div className="loginbody">
      <div className='logincontainer'>
        {/* Left Section */}
        <div className='leftSection'>
          <h1 style={{ fontSize: '24px' }}><b>Login</b></h1>
          <p>or use your email password</p>
          <input
            className='emailinput'
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {/* Password Input with Material Icon */}
          <div className='passDiv'>
            <input
              className='passInput'
              type={showPassword ? 'text' : 'password'} // Toggle password visibility
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={handleClickShowPassword} className='eyeicon'>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          <p style={{
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.3px',
          }}>Forgot Your Password?</p>
          <button className='signbutton' type="submit" onClick={handleLogin}>SIGN IN</button>
        </div>

        {/* Right Section */}
        <div className='rightSection'>
          <img
            src="https://jagatjitgroup.com/wp-content/uploads/2022/10/Jagat-Logo-Black-1-1.png"
            alt="Logo"
            className='loginlogo'
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
