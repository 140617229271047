import React, { useState } from 'react';
import Header from "../../Layout/Header"
import Sidemenu from '../../Components/sidemenu';
import DashContent from '../../Components/Dashboard/dashcontent';

function Dashboard() {
    const [isSideColumnOpen, setIsSideColumnOpen] = useState(true);
    const [isMainColumnOpen, setIsMainColumnOpen] = useState(true);

    const toggleSideColumn = () => {
        setIsSideColumnOpen(!isSideColumnOpen);
    };

    const toggleMainColumn = () => {
        setIsMainColumnOpen(!isMainColumnOpen);
    };

    return (
        <>
            {/* Full-width Header */}
            <Header toggleSideColumn={toggleSideColumn} toggleMainColumn={toggleMainColumn} />

            <main className="container-fluid">
                <div className="row">
                    {/* Side column */}
                    <div className={`col-lg-2 ${isSideColumnOpen ? 'd-block' : 'd-none'}`}>
                        <Sidemenu />
                    </div>

                    {/* Main column */}
                    <div className={`col-lg-${isSideColumnOpen ? '10' : '12'}`}>
                        <DashContent />
                    </div>
                </div>
            </main>
        </>
    );
}

export default Dashboard;
