import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Row, Col, Container, Card, Form, Alert } from 'react-bootstrap';
import axios_wrapper_general from '../../utils/axios_wrapper_general';
import { get_order } from '../../utils/endpoints';

// Define columns
const columns = [
  {
    name: 'Order ID',
    selector: row => row.Order_ID,
    sortable: true,
    width: '100px',
  },
  {
    name: 'Order Date',
    selector: row => row.m_order_date.split('T')[0],
    sortable: true,
    width: '120px',
  },
  {
    name: 'Branch Name',
    selector: row => row.Branch_Name,
    sortable: true,
    width: '130px',
    wrap: 'true',
  },
  {
    name: 'Billing Address',
    selector: row => row.m_billing_address,
    sortable: true,
    width: '200px',
    wrap: 'true',
  },
  {
    name: 'Company Name',
    selector: row => row.Company_Name,
    sortable: true,
    width: '200px',
    wrap: 'true',
  },
 

  {
    name: 'Shipping Address',
    selector: row => row.m_shipping_to_address,
    width: '200px',
    wrap: 'true',
  },
  {
    name: 'Expected Delivery Date',
    selector: row => row.m_expected_delivery_date,
    width: '180px',
    wrap: 'true',
  },
  {
    name: ' Implement',
    selector: row => row.Product,
    width: '150px',
    wrap: 'true',
  },
  {
    name: ' Variant',
    selector: row => row.Product_Variant,
    sortable: true,
    width: '200px',
    wrap: 'true',
  },
  {
    name: 'Quantity',
    selector: row => row.m_quantity,
    sortable: true,
    wrap: 'true',
  },
  {
    name: 'Price',
    selector: row => row.m_prize,
    sortable: true,
    wrap: 'true',
  },
  {
    name: 'Remarks',
    selector: row => row.m_remark,
    sortable: true,
    width: '200px',
  },
];

function OrderTable() {
  const today = new Date().toISOString().split('T')[0]; // Format date as yyyy-mm-dd
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!fromDate || !toDate) {
        setError('Both "From Date" and "To Date" are required.');
        setData([]); // Clear data if either date is missing
        return;
      }

      if (toDate >= fromDate) {
        const postData = {
          p_from_date: fromDate,
          p_to_date: toDate
        };
        const method = 'POST';

        try {
          const response = await axios_wrapper_general(get_order, postData, method);
          if (response && response.data) {
            setData(response.data.rows);
          }
          setError(null); // Clear any previous errors
        } catch (error) {
          console.error('Failed to fetch orders:', error);
          setError('Failed to fetch orders, please try again later.');
        }
      } else {
        setError('The "To Date" cannot be earlier than the "From Date".');
        setData([]); // Clear data if the date range is invalid
      }
    };

    // Only call the API if both dates are valid
    if (fromDate && toDate) {
      fetchOrders();
    } else {
      setData([]); // Clear data if dates are cleared
    }
  }, [fromDate, toDate]);

  // Handler for date changes
  const handleDateChange = (setDate) => (dateValue) => {
    setDate(dateValue); // Set the date using the respective setter
  };

  return (
    <div className="dashboard-div py-4">
      <Container className='main-container'>
        <Card style={{ padding: '0', borderRadius: '8px', backgroundColor: '#fff' }}>
          <Card.Body>
            <Card.Title style={{ fontWeight: '450', color: '#000' }}>View Orders</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs={2} className="mb-3">
                <Form.Group controlId="formFromDate">
                  <Form.Label className="formLabel">From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    max={today} // Ensure that only dates up to today are selectable
                    onChange={(e) => handleDateChange(setFromDate)(e.target.value)}
                    className="form-control form-control-sm"
                    style={{ borderRadius: '4px' }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2} className="mb-3">
                <Form.Group controlId="formToDate">
                  <Form.Label className="formLabel">To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="toDate"
                    value={toDate}
                    min={fromDate} // Ensure that To Date is not earlier than From Date
                  //  max={today} // Ensure that only dates up to today are selectable
                    onChange={(e) => handleDateChange(setToDate)(e.target.value)}
                    className="form-control form-control-sm"
                    style={{ borderRadius: '4px' }}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Display error if the date range is invalid */}
            {error && <Alert variant="danger">{error}</Alert>}

            <DataTable
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              pointerOnHover
              className="custom-data-table"
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default OrderTable;
