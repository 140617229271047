// SwalAlert.js
import Swal from 'sweetalert2';

// Function to show a confirmation alert
export const showConfirmation = async (message) => {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });
  return result.isConfirmed;
};

// Function to show a success alert
export const showSuccess = (message) => {
  Swal.fire({
    title: 'Success!',
    text: message,
    icon: 'success',
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'OK',
  });
};

// Function to show an error alert
export const showError = (message) => {
  Swal.fire({
    title: 'Error!',
    text: message,
    icon: 'error',
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'OK',
  });
};
