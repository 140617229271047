import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalComponent = ({ show, onHide, onConfirm, title, message }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="sm"
      style={{ zIndex: 1000000000 }}
      className="confirmation_modal"
    >
      <Modal.Header closeButton className="campaign_modal">
        <Modal.Title className="campaign_title fw-bold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row m-0 section-div ">
          <p style={{ color: "#000", fontWeight: "600", marginTop: "15px" }}>
            <label>{message}</label>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Button
            variant="success"
            style={{ width: "80px" }}
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            style={{ width: "80px" }}
            onClick={onHide}
          >
            No
          </Button>
        </div>
      </Modal.Footer>

    </Modal>
  );
};

export default ModalComponent;